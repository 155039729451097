import styled from "styled-components";
import Cookies from 'js-cookie';
import { useState, useEffect } from "react";
import { DefaultMessage } from "./DefaultMessage";

const getButtonColor = (type) => {
  switch (type) {
    case 'affirmative':
      return '#1c4d82'; // Blue color for affirmative button
    case 'negative':
      return '#C12D2A'; // Red color for negative button
    case 'neutral':
      return '#1c4d82'; // Blue color for neutral button
    default:
      return '#8E8D92'; // Grey color for default button
  }
};

const CookieMessageActionComponent = () => {
  const [cookieData, setCookieData] = useState(null);

  useEffect(() => {
    const cookie = Cookies.get('bldng_info_page_details');

    if (cookie) {
      const decodedCookie = decodeURIComponent(cookie);
      const parsedCookie = JSON.parse(decodedCookie);
      setCookieData(parsedCookie);
    }
  }, [cookieData]);

  return (
    <>
      <Container>
        {cookieData ? (
          <>
            <CustomH2>{cookieData.title}</CustomH2>
            <CustomParagraph>{cookieData.message}</CustomParagraph>
            {cookieData.actionButtons && (
              <ButtonContainer>
                {cookieData.actionButtons.map((button, index) => (
                  <StyledButton key={index} href={button.url} type={button.type}>{button.label}</StyledButton>
                ))}
              </ButtonContainer>
            )}
          </>
        ) : (
          <DefaultMessage />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(226, 226, 226);
  padding: 15px 20px;
  margin: 10px;
  min-width: 380px;

  p {
    font-size: 16px;
  }

  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
`;

const CustomH2 = styled.h2`
  margin-top: 0;
`;

const CustomParagraph = styled.p`
  margin-top: 1.5em;
  margin-bottom: 2em;
`;

const StyledButton = styled.a`
  display: inline-block;
  padding: 15px 25px;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 10px; /* Example margin */
  background-color: ${({ type }) => getButtonColor(type)};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 10px; /* Add spacing beneath each button except the last one */
  }

  &:last-child {
    margin-bottom: 5px; 
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ type }) => {
    switch (type) {
      case 'affirmative':
        return '#266899'; // Blue hover color
      case 'negative':
        return '#d63031'; // Red hover color
      case 'neutral':
        return '#266899'; // Blue hover color
      default:
        return getButtonColor(type); // Default to the button's color
    }
  }};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export { CookieMessageActionComponent };