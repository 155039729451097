import styled from "styled-components";
import BldngAiLogo from "../assets/bldng-ai-logo.jpg";
import Lottie from "lottie-react";
import BldngAiServices from "../assets/bldng-services.json";

const CompanyInfoSection = () => (
  <>
    <Container>
      <LogoContainer>
        <LogoImage src={BldngAiLogo} alt="Logo" />
        <LogoText>BLDNG.ai</LogoText>
      </LogoContainer>

      <AnimationContainer>
        <Lottie animationData={BldngAiServices} loop={2} />
      </AnimationContainer>
    </Container>
  </>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoText = styled.div`
  color: #1C4D82;
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 20px;
`;

const LogoImage = styled.img`
  height: 120px;
  width: 120px;
`;

const AnimationContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 0;
  padding-bottom: 50px;
`;

export { CompanyInfoSection };

