import styled from "styled-components";
import { CompanyInfoSection } from "./CompanyInfoSection";
import { CookieMessageActionComponent } from "./CookieMessageActionComponent";

const App = () => (
  <>
    <Container>
      <LeftColumn>
        <CompanyInfoSection />
      </LeftColumn>

      <RightColumn>
        <CookieMessageActionComponent/>
      </RightColumn>
    </Container>
  </>
);

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
`;

const LeftColumn = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 4.5;
  background-color: #fff;
  box-shadow: inset -1px 0 0 0 #dbdbdb;

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightColumn = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 5.5;
`;

export { App };