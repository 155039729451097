import styled from "styled-components";

const DefaultMessage = () => {
  return (
    <>
      <CustomH2>No messages to display</CustomH2>
      <CustomParagraph>Check back later for updates or contact support for assistance.</CustomParagraph>
    </>
  );
};

const CustomH2 = styled.h2`
  margin-top: 0;
`;

const CustomParagraph = styled.p`
  margin-top: 1.5em;
  margin-bottom: 2em;
`;

export { DefaultMessage };